import { Link } from 'react-router-dom';
import { useEffect, useState, createContext } from 'react';
import CustomAlert from './components/Alert';
import CustomModalAlert from './components/Modal';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCallback } from "react";
import config from './config';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const navbarBrandCvs = {
  color: 'white',
  cursor: 'pointer',
}

const navItem = {
  cursor: 'pointer',
}

interface AppContextProps {
  alertTypes: typeof alertTypes;
  alertType: string;
  showAlert: boolean;
  toggleAlert: (data: boolean) => void;
  toggleModalAlert: (data: boolean) => void;
  modalReturnValue: (returnVal: boolean) => void;
  changeAlertType: (alertType: string) => void;
  changeAlertPosition: (alertPosition: string) => void;
  changeUserID: (id: number) => void;
  getUserID: () => number;
  showModalAlert: boolean;
  modalAlertResponseValue: boolean;
  itemToDelete: (id: string) => void;
  itemToDeleteAfterDialog: string;
  setAlertMessage: (message: string) => void;
  setAlertTitle: (title: string) => void;
}

export const appContext = createContext<AppContextProps | undefined>(undefined);

export const alertTypes = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Success: 'success',
  Danger: 'danger',
  Warning: 'warning',
  Info: 'info',
  Light: 'light',
  Dark: 'dark',
});

export const alertPositions = Object.freeze({
  Top: 'top',
  Bottom: 'bottom',
});

const defaultAlertType = alertTypes.Info;
const defaultAlertPosition = alertPositions.Top;

function App() {
  const [jwtToken, setJwtToken] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userID, setUserID] = useState<number>(0);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<string>(defaultAlertType);
  const [alertPosition, setAlertPosition] = useState<string>(defaultAlertPosition);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showModalAlert, setShowModalAlert] = useState<boolean>(false);
  const [modalAlertResponseValue, setModalAlertResponseValue] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [itemToDeleteAfterDialog, setItemToDelete] = useState<string>("");

  console.log(`config.backendUrl: ${config.backendUrl}`)

  const [tickInterval, setTickInterval] = useState<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  const toggleAlert = (data: boolean) => {
    setShowAlert(data);
  }

  const toggleModalAlert = (data: boolean) => {
    setShowModalAlert(data);
  }

  const changeAlertType = (alertType: string) => {
    setAlertType(alertType);
  }

  const changeAlertPosition = (alertPosition: string) => {
    setAlertPosition(alertPosition);
  }

  const changeUserID = (id: number) => {
    setUserID(id);
  }

  const getUserID = () => {
    return userID;
  }

  const itemToDelete = (id: string) => {
    setItemToDelete(id);
  }

  const logOut = () => {
    const requestOptions:any = {
      method: 'GET',
      credentials: 'include',
    }

    fetch(`${config.backendUrl}/logout`, requestOptions)
      .catch(error => {
        console.log("error logging out", error);
      })
      .finally(() => {
        setJwtToken("");
        setIsAdmin(false);
        setUserID(0);
        toggleRefresh(false);
      });
      
    navigate("/");
  }

  const modalReturnValue = useCallback((returnVal: boolean) => {
    setShowModalAlert(false);
    console.log('Modal returned: ', returnVal);
    setModalAlertResponseValue(returnVal);
  }, []);

  const toggleRefresh = useCallback((status: boolean) => {
    if(status) {
      const i = setInterval(() => {
        const requestOptions:any = {
          method: 'GET',
          credentials: 'include',
        }

        fetch(`${config.backendUrl}/api/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if(data.access_token) {
              setJwtToken(data.access_token);
            } else {
              console.log('access token undefined');
            }

            if(data.is_admin) {
              setIsAdmin(data.is_admin);
              setUserID(data.user_id);
            }
            else {
              console.log('is_admin is undefined');
            }
          } else {
            console.log('data undefined');
          }
        })
        .catch(error => {
          console.log("error logging out");
        });
      }, 600000); // refresh auth 'cvszg' token every 600000 milliseconds (10 minutes)
      setTickInterval(i);
    } else {
      if (tickInterval) {
        clearInterval(tickInterval);
        setTickInterval(null);
      }
    }
  }, [tickInterval]);

  useEffect(() => {
    setLoading(false);

    if(jwtToken === '') {
      const requestOptions:any = {
        method: 'GET',
        credentials: 'include',
      }

      fetch(`${config.backendUrl}/api/refresh`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if(data.access_token) {
            setJwtToken(data.access_token);
            toggleRefresh(true);
          } else {
            console.log('access token undefined');
          }

          if(data.is_admin) {
            console.log('isadmin: ', data.is_admin);
            setIsAdmin(data.is_admin);
            setUserID(data.user_id);
          }
          else {
            console.log('is_admin is undefined');
          }

        } else {
          console.log('data undefined');
        }
      })
      .catch(error => {
        console.log("error logging out", error);
      });
    }
  }, [jwtToken, toggleRefresh]);

  const navigateTo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, relativeUrl: string) => {
    event.preventDefault();
    navigate(relativeUrl);
  }

  return (
    <>
     <appContext.Provider value={{ alertTypes, alertType, showAlert, toggleAlert, toggleModalAlert, modalReturnValue, changeAlertType, changeAlertPosition, changeUserID, getUserID, showModalAlert, modalAlertResponseValue, itemToDelete, itemToDeleteAfterDialog, setAlertMessage, setAlertTitle }}>
      <div id="root">
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
        <div className="site-mobile-menu-close">
          <span className="icofont-close js-menu-toggle"></span>
        </div>
          </div>
          <div className="site-mobile-menu-body"></div>
      </div>

      {isLoading ? (
        <Box className="skeleton-box">
          <Skeleton variant="rectangular" height={86} />
        </Box>
      ) : (
        <nav className={`main-nav navbar navbar-expand-lg fixed-top ${isLoading ? 'hidden' : ''}`}>
          <div className="container">
        <a className="navbar-brand" style={navbarBrandCvs} onClick={(event) => navigateTo(event, "/")}>
          <img className="logo" loading='lazy' style={navbarBrandCvs} src="./../images/byc-logo-sm.png" />
        </a>   
        <a className="navbar-brand" style={navbarBrandCvs} onClick={(event) => navigateTo(event, "/")}>  
          <span>CVS</span>     
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item" style={navbarBrandCvs}>
          <a className="nav-link active" aria-current="page" onClick={(event) => navigateTo(event, "/")}>Naslovnica</a>
            </li>
            <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Stranice
          </a>
          <ul className="dropdown-menu">
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/events")}>
            Raspored događanja
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item"  onClick={(event) => navigateTo(event, "/posts")}>
            Objave
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <hr className="dropdown-divider"/>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/vegetarianism")}>Vegetarijanstvo
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/ecology")}>
            Ekologija
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/vedic-knowledge")}>
            Vedsko znanje
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/bhakti")}>
            Bhakti Yoga
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/srila-prabhupada")}>
            Šrila Prabhupada
              </a>
            </li>
          </ul>
            </li>
            <li className="nav-item" style={navbarBrandCvs}>
          <a className="nav-link active" onClick={(event) => navigateTo(event, "/contact")}>
            Kontakt
          </a>
            </li>
            {jwtToken !== "" && isAdmin && userID !== 0 && 
            <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Administracija
          </a>
          <ul className="dropdown-menu">
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, `/admin/user-profile/${userID}`)}>
            Uredi svoj korisnički profil
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-events")}>
            Uredi događanja
              </a>
            </li>
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-posts")}>
            Uredi objave
              </a>
            </li>
          </ul>
            </li>
            }
            {jwtToken !== "" && !isAdmin &&
            <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Administracija
          </a>
          <ul className="dropdown-menu">
            <li style={navbarBrandCvs}>
              <a className="dropdown-item" onClick={(event) => navigateTo(event, `/admin/user-profile/${userID}`)}>
            Uredi svoj korisnički profil
              </a>
            </li>
          </ul>
            </li>
            }
            <li className="nav-item" style={navbarBrandCvs}>
          {jwtToken === ""
          ? <a id='login-button' className="nav-link active" onClick={(event) => navigateTo(event, "/login")}>
              <span className='badge bg-success'>
            Ulogiraj se
              </span>
            </a>
          : <a id='logout-button' className="nav-link active" onClick={logOut}>
              <span className='badge bg-danger'>
            Odlogiraj se
              </span>
            </a>
          }
            </li>
          </ul>
        </div>
          </div>
        </nav>
      )}
      <div style={{ paddingTop: '86px' }}></div>
        {alertPosition === alertPositions.Top && (
        <>
          <CustomAlert message={alertMessage} title={alertTitle} alertType={alertType} showAlert={showAlert}/>
        </>
        )}
        <CustomModalAlert message={alertMessage} title={alertTitle} showModalAlert={showModalAlert} />
        <Outlet context={{
      jwtToken,
      setJwtToken,
      setIsAdmin,
      setUserID,
      setAlertTitle,
      setAlertMessage,
      setAlertType,
      setAlertPosition,
      setShowAlert,
      toggleRefresh,
      modalReturnValue,
      modalAlertResponseValue,
      itemToDelete,
      itemToDeleteAfterDialog,
         }} />

        {alertPosition === alertPositions.Bottom && (
        <>
          <CustomAlert message={alertMessage} title={alertTitle} alertType={alertType} showAlert={showAlert}/>
        </>
        )}
        <footer className="site-footer" style={{ position: 'fixed', bottom: 0, width: '100%' }}>
         <div className="container">
       <div className="row">
      <div className="col-12 text-center">
      <div className="widget">
        {/* <p style={{ fontSize: 'x-small' }}>Pratite nas na</p> */}
        <Link className='btn' to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A"><span className="icon-youtube"></span></Link>
        <Link className='btn' to="https://www.facebook.com/BYCZagreb"><span className="icon-facebook"></span></Link>
        <p style={{ fontSize: 'x-small' }}>
         Centar za vedske studije Zagreb<br/>&copy; Copyright 2016-{getCurrentYear()}<br/>Sva prava pridržana
        </p>
      </div> 
      </div>
       </div>
        
       </div>
      </footer>
      <div style={{ paddingBottom: `${(document.querySelector('.site-footer') as HTMLElement)?.offsetHeight || 0}px` }}></div>

      </div>
    </appContext.Provider>
    </>
  );
}

export default App;
