import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const headerImage: React.CSSProperties = {
    height: '40vh',
    backgroundImage: "url('./images/ecology.jpg')",
    padding: '0',
};

const EcologyPage: React.FC = () => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
        <>
        <Box className="site-cover site-cover-sm same-height overlay single-page" style={headerImage}>
            <Skeleton variant="rectangular" width="100%" height="100%" id="header-skeleton" />
            <div
                style={{ ...headerImage, display: 'none' }}
                onLoad={() => {
                document.getElementById('header-skeleton')!.style.display = 'none';
                (document.querySelector('.site-cover') as HTMLElement).style.display = 'block';
                }}
            />
        </Box>
        <section className="section">
            <div className="container">

                <div className="row blog-entries element-animate">

                    <div className="col-md-12 col-lg-8 main-content">

                    <div className="post-content-body">
                        <br/>
                        <h1>Ekologija</h1>
                        <br/>
                        <p>Moderne metode uzgoja i proizvodnje mlijeka pridonijele su pustošenju ekosustava. Današnja poljoprivreda, koja osigurava prehranu ljudskog društva, uvelike ovisi o nafti i kemijskim gnojivima. Iako su u početku povećavale prinos (u prvim godinama), ove kemikalije su se pokazale štetnima za plodnost tla, ponekad ga ostavljajući otrovnim i neproduktivnim. Genetski modificirani usjevi donose vlastiti skup etičkih i zdravstvenih problema, što dovodi do svijeta u kojem neke prehrambene proizvode promiče jedna vlada, a zabranjuje druga.</p>
                        <p>Osnova cjelokupne poljoprivrede ostaje ovisna o prirodi u obliku tla, zraka i vode. Uspostavljanje metoda uzgoja u skladu s prirodom može osigurati dugoročnu održivost. U protivnom, upozoravaju nas stručnjaci, možda idemo prema globalnoj prehrambenoj krizi.</p>
                        <div className="row my-4">
                        <div className="col-md-12 mb-4">
                            <Box position="relative">
                                <Skeleton variant="rectangular" width="100%" height={200} />
                                <img
                                    src="./images/eco/eco1.jpg"
                                    alt="Ecology"
                                    className="img-fluid rounded"
                                    onLoad={(e) => {
                                        e.currentTarget.style.display = 'block';
                                        (e.currentTarget.previousElementSibling as HTMLElement)!.style.display = 'none';
                                    }}
                                    onError={(e) => {
                                        e.currentTarget.style.display = 'none';
                                        (e.currentTarget.previousElementSibling as HTMLElement).style.display = 'none';
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </Box>
                        </div>
                        <div className="col-md-6 mb-4">
                            <Box position="relative">
                                <Skeleton variant="rectangular" width="100%" height={200} />
                                <img
                                    src="./images/eco/eco2.jpg"
                                    alt="Ecology"
                                    className="img-fluid rounded"
                                    onLoad={(e) => {
                                        e.currentTarget.style.display = 'block';
                                        (e.currentTarget.previousElementSibling as HTMLElement)!.style.display = 'none';
                                    }}
                                    onError={(e) => {
                                        e.currentTarget.style.display = 'none';
                                        (e.currentTarget.previousElementSibling as HTMLElement).style.display = 'none';
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </Box>
                        </div>
                        <div className="col-md-6 mb-4">
                            <Box position="relative">
                                <Skeleton variant="rectangular" width="100%" height={200} />
                                <img
                                    src="./images/eco/eco3.jpg"
                                    alt="Ecology"
                                    className="img-fluid rounded"
                                    onLoad={(e) => {
                                        e.currentTarget.style.display = 'block';
                                        (e.currentTarget.previousElementSibling as HTMLElement)!.style.display = 'none';
                                    }}
                                    onError={(e) => {
                                        e.currentTarget.style.display = 'none';
                                        (e.currentTarget.previousElementSibling as HTMLElement).style.display = 'none';
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </Box>
                        </div>
                        </div>
                        <p>U ISKCON-ovim poljoprivrednim zajednicama ili eko-selima, ističe se važnost duhovne ekologije: potrebu da živimo u skladu sa sobom, prirodom i Božanskim. Ove ruralne zajednice promiču održivost i duhovnost na temelju načela poštovanja svakog živog bića.</p>
                        <p>Postoji preko 40 ISKCON-ovih eko-sela i poljoprivrednih zajednica diljem svijeta temeljenih na ovom etosu. Neki postoje "izvan mreže", u potpunosti ovisni o prirodnoj energiji iz vode i sunca. Drugi jednostavno zagovaraju odgovorniji i prirodniji život temeljen na održivoj brizi o zemlji i kravama. Za razliku od modernih agrobiznisa koji iskorištavaju krave i druge životinje za njihovo mlijeko, meso i jaja, farme ISKCON-a štite krave tijekom cijelog njihovog života, omogućujući im da žive mirno čak i nakon završetka godina proizvodnje mlijeka.</p>
                        <p><b>„Postoji sladak transcendentalan međuodnos između svih živih bića, bez obzira na kastu, vjeru i boju kože, ne samo između čovjeka i čovjeka, već između čovjeka i životinje, čovjeka i ptice, čovjeka i gmazova, čovjeka i biljaka, itd., te tako također između čovjeka i Boga, i između Boga i drugih, i tako dalje. Takve farme služe kao središte vrhunske kulture znanja. Zapravo daju primjer da ni Bog, ni živo biće, ni priroda nisu ni na koji način antagonistični jedni prema drugima, već da svi oni postoje u harmoniji kao potpuna cjelina.”</b> - Srila Prabhupada</p>
                    </div>
                
                    <br/>
                    <br/>
                    </div>
                    <div className="col-md-12 col-lg-4 sidebar">
                        <div className="sidebar-box">
                            <div className="bio text-center" >
                                <Box position="relative" style={{ textAlign: 'center' }} component="div">
                                    <Skeleton variant="circular" width={150} height={150} style={{ margin: '0 auto', display: 'block' }} id="skeleton-loader" />
                                    <img
                                        src="./images/authors/sundarananda.jpg"
                                        alt="Image Placeholder"
                                        className="img-fluid mb-3"
                                        onLoad={(e) => {
                                            e.currentTarget.style.display = 'block';
                                            document.getElementById('skeleton-loader')!.style.display = 'none';
                                        }}
                                        onError={(e) => {
                                            e.currentTarget.style.display = 'none';
                                            document.getElementById('skeleton-loader')!.style.display = 'none';
                                        }}
                                        style={{ display: 'none', borderRadius: '50%', margin: '0 auto' }}
                                    />
                                </Box>
                                <div className="bio-body">
                                    <h6><i>autor članka:</i></h6>
                                    <h6><b>Tomislav Klasnić (Sundarananda das)</b></h6>
                                    <h6></h6>
                                    <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. <Link to="" onClick={handleExpandClick}>više...</Link></p>
                                    
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <p>Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBooku</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTubeu</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
              
        </>
    );

}

export default EcologyPage;
