import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Posts from './Posts';
import Events from './Events';
import { Youtube } from 'react-bootstrap-icons';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface CarouselItem {
    id: number;
    name: string;
    description: string;
    link: string;
    image: string;
}

const items: CarouselItem[] = [
    { id: 0, name: "Vedsko znanje", description: "Probably the most random thing you have ever seen!", link: "/vedic-knowledge", image: "./images/carousel/vedic_knowledge.jpg" },
    { id: 1, name: "Izdana je nova knjiga - \"Bhagavad-gita za svakoga – odgovori na ključna životna pitanja\"", description: "Bhagavad gita za svakoga", link: "https://atma.hr/bhagavad-gita-za-svakoga-odgovori-na-kljucna-zivotna-pitanja/", image: "./images/carousel/bg_for_everyone.jpg" },
    { id: 2, name: "Vegetarijanstvo", description: "Probably the most random thing you have ever seen!", link: "/vegetarianism", image: "./images/carousel/vegetarianism.jpg" },
    { id: 3, name: "Bhakti yoga - yoga ljubavi", description: "Probably the most random thing you have ever seen!", link: "/bhakti", image: "./images/carousel/kirtan.jpg" },
    { id: 4, name: "Šrila Prabhupada", description: "Probably the most random thing you have ever seen!", link: "/srila-prabhupada", image: "./images/carousel/srila_prabhupada.jpg" },
    { id: 5, name: "Izdana je nova knjiga - \"Bhagavad-gita za svakoga – odgovori na ključna životna pitanja\"", description: "Bhagavad gita za svakoga", link: "https://atma.hr/bhagavad-gita-za-svakoga-odgovori-na-kljucna-zivotna-pitanja/", image: "./images/carousel/bg_for_everyone.jpg" },
    { id: 6, name: "Ekologija", description: "Probably the most random thing you have ever seen!", link: "/ecology", image: "./images/carousel/ecology.jpg" },
];

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({});

    const handleImageLoad = (id: number) => {
        setLoadedImages(prev => ({ ...prev, [id]: true }));
    };

    const navigateTo = (event: React.MouseEvent<HTMLDivElement>, relativeUrl: string) => {
        event.preventDefault();
        if (relativeUrl.startsWith("http")) {
            window.open(relativeUrl, '_blank');
        } else {
            navigate(relativeUrl);
        }
    };

    return (
        <>
            <section className="section bg-light">
                <div className="container">
                    <div className="row align-self-center retro-layout">
                        <Carousel interval={15000}>
                            {items.map(item => (
                                <Carousel.Item key={item.id} style={{ cursor: 'pointer' }}>
                                    <div onClick={(event) => navigateTo(event, item.link)} style={{ position: 'relative' }}>
                                        {/* Show Skeleton until the image is fully loaded */}
                                        {!loadedImages[item.id] && (
                                            <Box sx={{ width: '100%', height: '775px' }}>
                                                <Skeleton variant="rectangular" width="100%" height="100%" />
                                            </Box>
                                        )}
                                        
                                        {/* Image is initially hidden and only shown when loaded */}
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                            // loading='lazy'
                                            onLoad={() => handleImageLoad(item.id)}
                                            style={{
                                                display: loadedImages[item.id] ? 'block' : 'none',  // Hide image until fully loaded
                                                opacity: loadedImages[item.id] ? 1 : 0,  // Prevent flickering
                                                transition: 'opacity 0.5s ease-in-out', // Smooth fade-in effect
                                                width: '100%', // Ensure image takes full width
                                                height: 'auto' // Maintain aspect ratio
                                            }}
                                        />
                                    </div>
                                    <Carousel.Caption>
                                        <h5>{item.name}</h5>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section>

            <section className="section posts-entry">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <h5 className="posts-entry-title">Raspored događanja</h5>
                        </div>
                        <div className="col-sm-6 text-sm-end">
                            <Link to={`/events`} className="btn btn-sm btn-outline-primary">Sva događanja</Link>
                        </div>
                    </div>
                    <Events />
                </div>
            </section>

            <section className="section posts-entry posts-entry-sm bg-light">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <h5 className="posts-entry-title"><Youtube color="red" size={26} /> Naši YouTube kanali</h5>
                        </div>
                        <div className="col-sm-6 text-sm-end">
                            <a href="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A" className="btn btn-sm btn-outline-primary" target="_blank" rel="noopener noreferrer">Otvori sve</a>
                        </div>
                    </div>
                    <div className="row g-3 justify-content-center">
                        <div className="col-md-3 d-flex justify-content-center">
                            <Card sx={{ maxWidth: 345 }}>
                                <Box sx={{ width: '100%', height: 140, position: 'relative' }}>
                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                    <CardMedia
                                        component="img"
                                        loading="lazy"
                                        sx={{ height: 140 }}
                                        image="./images/cards/yt-channels/bg.jpg"
                                        title="Božanska pjesma"
                                        onLoad={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'block';
                                            target.previousElementSibling?.remove();
                                        }}
                                    />
                                </Box>
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Božanska pjesma
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        (Bhagavad Gita)
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Bhagavad Gita, drevni sveti tekst Indije, univerzalno se smatra glavnim izvorom duhovnog znanja i inspiracije za čovječanstvo. Sedam stotina stihova Bhagavad Gite, koje je Gospodin Sri Krishna izravno izgovorio svom bliskom prijatelju Arjuni, predstavlja konačan vodič u znanost samospoznaje.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" component="div">
                                        predavač: Sundarananda Das (Tomislav Klasnić)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => window.open("https://www.youtube.com/playlist?list=PLV1Ci4gdduxwR8rKb4Sz7v1nkn74sf1KS", '_blank')}>Otvori</Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <Card sx={{ maxWidth: 345 }}>
                                <Box sx={{ width: '100%', height: 140, position: 'relative' }}>
                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                    <CardMedia
                                        component="img"
                                        loading="lazy"
                                        sx={{ height: 140 }}
                                        image="./images/cards/yt-channels/sb.jpg"
                                        title="Bhagavat purana"
                                        onLoad={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'block';
                                            target.previousElementSibling?.remove();
                                        }}
                                    />
                                </Box>
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Bhagavat purana
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        (Śrimad Bhagavatam)
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Za vedsku literaturu se ponekad kaže da je "drvo želja", drvo koje može dati što god netko poželi, a od tog stabla se za Śrimad-Bhagavatam kaže da je zrelo i najukusnije voće. Osamnaest tisuća stihova Bhagavatama sadrži stotine razgovora između samospoznatih kraljeva, yogija i mudraca drevnog svijeta o temi kako postići savršenstvo u životu.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" component="div">
                                        predavač: Sundarananda Das (Tomislav Klasnić)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => window.open("https://www.youtube.com/playlist?list=PLV1Ci4gdduxy7030hq5GTSsXElTKypARY", '_blank')}>Otvori</Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <Card sx={{ maxWidth: 345 }}>
                                <Box sx={{ width: '100%', height: 140, position: 'relative' }}>
                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                    <CardMedia
                                        component="img"
                                        loading="lazy"
                                        sx={{ height: 140 }}
                                        image="./images/cards/yt-channels/pbc.jpg"
                                        title="Mjesečina Ljubavne predanosti"
                                        onLoad={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'block';
                                            target.previousElementSibling?.remove();
                                        }}
                                    />
                                </Box>
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Mjesečina Ljubavne predanosti
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        (Prema Bhakti Chandrika)
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Prema Bhakti Candrika je možda najvažniji od svih djela Srila Narottame dase Thakura koje je napisao prije nešto više od četiri stotine godina. Prema bhakti-candrika popularno je poznata kao Sri Rupanuga-gita jer sadrži bit svih učenja Gospodina Caitanye Mahaprabhua.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" component="div">
                                        predavač: Nityananda Ram Das 
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => window.open("https://www.youtube.com/playlist?list=PLV1Ci4gdduxwXh_T-nwZ9gjocVayRmVQo", '_blank')}>Otvori</Button>
                                </CardActions>
                            </Card>
                        </div>
                    </div>
                    <br/>
                    <div className="row g-3 justify-content-center">
                        <div className="col-md-3 d-flex justify-content-center">
                            <Card sx={{ maxWidth: 345 }}>
                                <Box sx={{ width: '100%', height: 140, position: 'relative' }}>
                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                    <CardMedia
                                        component="img"
                                        loading="lazy"
                                        sx={{ height: 140 }}
                                        image="./images/cards/yt-channels/cc.jpg"
                                        title="Nektar života Gospodina Caitanye"
                                        onLoad={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'block';
                                            target.previousElementSibling?.remove();
                                        }}
                                    />
                                </Box>
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Nektar života Gospodina Caitanye
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        (Caitanya Caritamrita)
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        Sri Caitanya-caritamrta je glavno djelo o životu i učenjima Sri Krishne Caitanye, Svevišnje Božanske Osobe koja se pojavljuje kao vlastiti bhakta. Bio je pionir velikog društvenog i vjerskog pokreta koji je započeo u Indiji prije otprilike 500 godina i koji je izravno i neizravno utjecao na tijek religijskog i filozofskog razmišljanja u cijelom svijetu.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" component="div">
                                        predavačica: Loka Pavani Devi Dasi (Lea Kušanić)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => window.open("https://www.youtube.com/playlist?list=PLV1Ci4gdduxxRlr5kw33GdVBfXt9TUUIS", '_blank')}>Otvori</Button>
                                </CardActions>
                            </Card>
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <Card sx={{ maxWidth: 345 }}>
                                 <Box sx={{ width: '100%', height: 140, position: 'relative' }}>
                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                    <CardMedia
                                        component="img"
                                        loading="lazy"
                                        sx={{ height: 140 }}
                                        image="./images/cards/yt-channels/sretna-dusa.jpg"
                                        title="Sretna duša"
                                        onLoad={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'block';
                                            target.previousElementSibling?.remove();
                                        }}
                                    />
                                </Box>
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                    Sretna duša
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        YouTube kanal Sretna duša predstavlja vanvremensko znanje Veda - kako ostvariti istinsku sreću duše. Takva je sreća neograničena i nije uvjetovana izvanjskim materijalnim okolnostima. Osoba koja ostvaruje sreću duše djeluje konstruktivno i s ljubavlju čak i u vrlo izazovnim situacijama.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body2" component="div">
                                        predavač: Jananivas Das (Gordan Jurković)
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => window.open("https://www.youtube.com/@sretna-du%C5%A1a/videos", '_blank')}>Otvori</Button>
                                </CardActions>
                            </Card>
                        </div>
                    </div>
                    <br/>
                </div>
                <br/>
                <br/>

            </section>

            

            <section className="section posts-entry">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <h5 className="posts-entry-title">Objave</h5>
                        </div>
                        <div className="col-sm-6 text-sm-end">
                            <Link to={`/posts`} className="btn btn-sm btn-outline-primary">Sve objave</Link>
                        </div>
                    </div>
                    <Posts />
                </div>
            </section>
        </>
    );
};

export default Home;
