import { useEffect, useState, useContext, ChangeEvent, FormEvent } from "react";
import { useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Input from "./form/Input";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
import { appContext, alertTypes } from '../App';
import config from '../config';

import Button from '@mui/material/Button';

dayjs.extend(utc);

interface EventPayload {
    id: number;
    title: string;
    author: number;
    category: number;
    description: string;
    start_datetime: Dayjs;
    end_datetime: Dayjs;
    created_at: string;
    updated_at: string;
}

const CreateEvent: React.FC = () => {
    const rteRef = useRef<any>(null);
    const navigate = useNavigate();
    const context = useContext(appContext);
    if (!context) {
        throw new Error("appContext must be used within a AppProvider");
    }
    const { toggleAlert, changeAlertType } = context;
    const { jwtToken, setAlertTitle, setAlertMessage } = useOutletContext<any>();
    const [errors, setErrors] = useState<string[]>([]);

    const eventCategories = [
        { id: 1, value: "Bhagavad Gita" },
        { id: 2, value: "Srimad Bhagavatam" },
        { id: 3, value: "Kirtan večer" },
        { id: 4, value: "Posebna prigoda" },
        { id: 5, value: "Festival" },
        { id: 6, value: "Ostalo" },
    ];

    const hasError = (key: string) => {
        return errors.indexOf(key) !== -1;
    };

    const [eventPayload, setEvent] = useState<EventPayload>({
        id: 0,
        title: "",
        author: 1,
        category: 0,
        description: "",
        start_datetime: dayjs.utc(new Date()),
        end_datetime: dayjs.utc(new Date()),
        created_at: "",
        updated_at: "",
    });

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
    }, [jwtToken, navigate]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        eventPayload.created_at = dayjs.utc(new Date()).toISOString();
        eventPayload.updated_at = dayjs.utc(new Date()).toISOString();

        const requestOptions:any = {
            method: "POST",
            headers: headers,
            credentials: 'include',
            body: JSON.stringify(eventPayload),
        };

        fetch(`${config.backendUrl}/admin/create-event`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Event was successfully created!");
                    setAlertMessage(data.message);
                    navigate(`/admin/events/${data.data}`);
                }

                toggleAlert(true);
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                console.log(err);
            });
    };

    const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;

        setEvent({
            ...eventPayload,
            [name]: value
        });
    };

    const handleCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }
        const name = event.target.name;

        setEvent({
            ...eventPayload,
            [name]: value
        });
    };

    const getEventStartDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            return dayjs.utc(eventDateTime);
        }

        return dayjs.utc(new Date());
    };

    const getEventEndDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            return dayjs.utc(eventDateTime);
        }
    };

    const setEventStartDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            const date = dayjs.utc(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                start_datetime: date
            });
        }
    };

    const setEventEndDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            const date = dayjs.utc(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                end_datetime: date
            });
        }
    };

    const handleDescriptionChange = () => (event: any) => {
        console.log(event);

        setEvent({
            ...eventPayload,
            description: rteRef.current?.editor?.getHTML()
        });
    };

    return (
        <>
            <br />
            <div className="col-md-10 offset-md-1">
                <div><h1>Create new event</h1></div>
                <hr />
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value={eventPayload.id} id="id" />
                    <Input
                        title={"Title"}
                        className={"form-control"}
                        type={"text"}
                        name={"title"}
                        value={eventPayload.title}
                        onChange={handleChange("title")}
                        errorDiv={hasError("title") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a title"}
                    />
                    <Button type="submit" variant="outlined">Next</Button>
                </form>
            </div>
            <br />
        </>
    );
};

export default CreateEvent;
