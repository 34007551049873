import { useEffect, useState, useContext } from "react";
import {appContext, alertTypes} from '../../App';
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import config from '../../config';

import { useTheme } from "@mui/material";
import {
  ImageNodeAttributes,
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from "mui-tiptap";



export default function EditorMenuControls() {
  const { jwtToken } = useOutletContext() as { jwtToken: string };
  const { toggleAlert, changeAlertType } = useContext(appContext) as { toggleAlert: (value: boolean) => void, changeAlertType: (type: string) => void } || {};
  const { setAlertTitle } = useOutletContext() as { setAlertTitle: (title: string) => void }; 
  const { setAlertMessage } = useOutletContext() as { setAlertMessage: (message: string) => void};
  const navigate = useNavigate();

  let { id } = useParams();

  const uploadImage = async (file: string | Blob) => {
    
    if(jwtToken === "") {
        navigate("/login");
        return;
    }
    const headers = new Headers();
     //headers.append("Content-Type", "multipart/form-data; boundary=MultipartBoundry");
     headers.append("Authorization", `Bearer ${jwtToken}`);
     //headers.append("Accept-Encoding", "gzip, deflate")

    const formData = new FormData();
    formData.append("file", file);

    const requestOptions: RequestInit = {
      method: "Post",
      headers: headers,
      credentials: 'include' as RequestCredentials,
      body: formData,
    };

    return fetch(`${config.backendUrl}/admin/upload-image/${"event"}/${id}`, requestOptions)
      .then((response) => {
          if(response.status === 401) {
              console.log('Unauthorized');
              navigate("/login");
          }

          return response.json();
      })
      .then((data) =>{
        if(data.error) {
            console.log(data.error)
            return data.error;
        } else {
            console.log('uploaded image successfully')
            return data;
        }

      })
      .catch(err => {
        console.log(err)
        return err;
      });
  }


  return (
    <MenuControlsContainer>
      <MenuSelectFontFamily
        options={[
          { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
          { label: "Cursive", value: "cursive" },
          { label: "Monospace", value: "monospace" },
          { label: "Serif", value: "serif" },
        ]}
      />

      <MenuDivider />

      <MenuSelectHeading />

      <MenuDivider />

      <MenuSelectFontSize />

      <MenuDivider />

      <MenuButtonBold />

      <MenuButtonItalic />

      <MenuButtonUnderline />

      <MenuButtonStrikethrough />

      <MenuButtonSubscript />

      <MenuButtonSuperscript />

      <MenuDivider />

      <MenuButtonTextColor
        // defaultTextColor={theme.palette.text.primary}
        swatchColors={[
          { value: "#000000", label: "Black" },
          { value: "#ffffff", label: "White" },
          { value: "#888888", label: "Grey" },
          { value: "#ff0000", label: "Red" },
          { value: "#ff9900", label: "Orange" },
          { value: "#ffff00", label: "Yellow" },
          { value: "#00d000", label: "Green" },
          { value: "#0000ff", label: "Blue" },
        ]}
      />

      <MenuButtonHighlightColor
        swatchColors={[
          { value: "#595959", label: "Dark grey" },
          { value: "#dddddd", label: "Light grey" },
          { value: "#ffa6a6", label: "Light red" },
          { value: "#ffd699", label: "Light orange" },
          // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
          { value: "#ffff00", label: "Yellow" },
          { value: "#99cc99", label: "Light green" },
          { value: "#90c6ff", label: "Light blue" },
          { value: "#8085e9", label: "Light purple" },
        ]}
      />

      <MenuDivider />

      <MenuButtonEditLink />

      <MenuDivider />

      <MenuSelectTextAlign />

      <MenuDivider />

      <MenuButtonOrderedList />

      <MenuButtonBulletedList />

      <MenuButtonTaskList />

      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />

          <MenuButtonUnindent />
        </>
      )}

      <MenuDivider />

      <MenuButtonBlockquote />

      <MenuDivider />

      <MenuButtonCode />

      <MenuButtonCodeBlock />

      <MenuDivider />

      {/* <MenuButtonImageUpload
        onUploadFiles={ (files) => {
          return Promise.all(files.map((file) => {
            return uploadImage(file).then((response: any) => ({
              src: `/api/get-image?filename=${response.data}`,
              alt: file.name,
            }));
          }));
        }}
      /> */}
      <MenuButtonImageUpload
        onUploadFiles={ (files) => {
          return Promise.all(files.map((file) => {
            return uploadImage(file).then((response: any) => {
              return {
                src: `https://minio.cvs-zagreb.com/${response.data}`,
                alt: response.data,
              };
            })
          }));
        }}
      />

      <MenuDivider />

      <MenuButtonHorizontalRule />

      <MenuButtonAddTable />

      <MenuDivider />

      <MenuButtonRemoveFormatting />

      <MenuDivider />

      <MenuButtonUndo />
      <MenuButtonRedo />
    </MenuControlsContainer>
  );
}
