import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useOutletContext, useParams, useLocation } from "react-router-dom";
import { appContext, alertTypes, alertPositions } from '../App';
import LeaveComment from './form/LeaveComment';
import config from '../config';

interface Post {
    title: string;
    statuses?: string[];
    html: string;
}

interface CommentPayload {
    user_id: number;
    parent_comment_id: number;
    entity_type: string;
    entity_id: number;
    content: string;
}

interface Comment {
    id: number;
    parent_comment_id: number;
    entity_type: string;
    entity_id: number;
    first_name: string;
    last_name: string;
    created_at: string;
    content: string;
    level: number;
    showReplyForm: boolean;
}

const PostPage: React.FC = () => {
    const navigate = useNavigate();
    const [shouldFocus, setShouldFocus] = useState(false);
    const commentRef = useRef<HTMLTextAreaElement | null>(null);

    const location = useLocation();
    const { jwtToken, setAlertTitle, setAlertMessage } = useOutletContext<any>();
    const appCtx = useContext(appContext);
    const { toggleAlert, changeAlertType, changeAlertPosition, getUserID } = appCtx || {};
    const [post, setPost] = useState<Post>({ title: "", html: "" });
    const [comments, setComments] = useState<Comment[]>([]);
    const { id } = useParams<{ id: string }>();
    const [replyFormVisibility, setReplyFormVisibility] = useState<{ [key: number]: boolean }>({});

    const [commentPayload, setComment] = useState<CommentPayload>({
        user_id: 0,
        parent_comment_id: 0,
        entity_type: "",
        entity_id: 0,
        content: "",
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>, comment: string, parentCommentId: number): any => {
        event.preventDefault();
        console.log(comment);

        let userId: number = getUserID ? Number(getUserID()) : 0;
        if (isNaN(userId)) {
            userId = 0;
        }

        const payload: CommentPayload = {
            user_id: userId,
            parent_comment_id: parentCommentId,
            entity_type: "post",
            entity_id: id ? Number(id) : 0,
            content: comment,
        };

        if (jwtToken === "" || userId === 0) {
            if (changeAlertType) changeAlertType(alertTypes.Danger);
            if (changeAlertPosition) changeAlertPosition(alertPositions.Top);
            setAlertTitle("Upozorenje");
            setAlertMessage("Mozete ostaviti komentar samo ako ste ulogirani.");
            if (toggleAlert) toggleAlert(true);
            window.scrollTo(0, 0);
            return null;
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            credentials: 'include',
            body: JSON.stringify(payload),
        };

        fetch(`${config.backendUrl}/admin/add-comment`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    if (changeAlertType) changeAlertType(alertTypes.Danger);
                    if (changeAlertPosition) changeAlertPosition(alertPositions.Bottom);
                    setAlertTitle("Upozorenje");
                    setAlertMessage(data.message);
                    window.scrollTo(0, 0);
                    if (toggleAlert) toggleAlert(true);
                } else {
                    if (commentRef.current) {
                        commentRef.current.value = "";
                    }
                }

                setReplyFormVisibility({});
                if (id) {
                    getComments(id);
                }
                return null;
            })
            .catch(err => {
                if (changeAlertType) changeAlertType(alertTypes.Danger);
                if (changeAlertPosition) changeAlertPosition(alertPositions.Top);
                setAlertTitle("Greška");
                setAlertMessage(err.toString());
                if (toggleAlert) toggleAlert(true);
                window.scrollTo(0, 0);
                console.log(err);
                return null;
            });
    };

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        fetch(`${config.backendUrl}/api/posts/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPost(data.post);
            })
            .catch(err => {
                console.log(err);
            });
    }, [id]);

    useEffect(() => {
        if (id) {
            getComments(id);
        }
    }, [id, location]);

    const getComments = (id: string) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        };

        fetch(`${config.backendUrl}/api/get-comments/post/${id}?commentsView=3`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setComments(data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    if (post.statuses) {
        post.statuses = Object.values(post.statuses);
    } else {
        post.statuses = [];
    }

    const handleFocusState = () => {
        setShouldFocus(true);
    };

    useEffect(() => {
        if (shouldFocus && commentRef.current) {
            commentRef.current.focus();
            setShouldFocus(false);
        }
    }, [shouldFocus]);

    const handleReplyClick = (sub_comment: Comment) => {
        setReplyFormVisibility(prevState => ({
            ...prevState,
            [sub_comment.id]: !prevState[sub_comment.id]
        }));
        console.log(`Reply clicked for comment ID: ${sub_comment.id}`);
    };

    const getNestedComments = (parentId: number) => {
        return comments
            .filter(comment => comment.level > 1 && comment.parent_comment_id === parentId)
            .sort((a, b) => a.level - b.level);
    };

    const renderNestedComments = (parentId: number) => {
        const nestedComments = getNestedComments(parentId);
        if (nestedComments.length === 0) return null;

        return (
            <ul className="children">
                {nestedComments.map((sub_comment, sub_index) => (
                    <li className="comment" key={sub_index}>
                        <div className="vcard">
                            <img src="/images/placeholders/person.jpg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="comment-body">
                            <h3>{sub_comment.first_name} {sub_comment.last_name}</h3>
                            <div className="meta">{new Date(sub_comment.created_at).toLocaleString()}</div>
                            <p>{sub_comment.content}</p>
                            <button className="reply rounded" onClick={() => handleReplyClick(sub_comment)}>Reply</button>
                            {replyFormVisibility[sub_comment.id] && (
                                <LeaveComment 
                                    parent_id={sub_comment.id} 
                                    handleSubmit={(event, comment) => handleSubmit(event, comment, sub_comment.id)} 
                                    autoFocus={true}
                                />
                            )}
                        </div>
                        {renderNestedComments(sub_comment.id)}
                    </li>
                ))}
            </ul>
        );
    };

    function renderPost(html: string) {
        return { __html: html };
    }

    return (
        <>
            <div>
                <div className="container">
                    <div className="row same-height justify-content-center">
                        <div className="col-md-12">
                            <div className="post-entry text-center">
                                <br />
                                <br />
                                <h1 className="mb-12">{post.title}</h1>
                                <br />
                                <br />
                                {post.statuses.map((s, index) => (
                                    <span key={index} className='badge bg-secondary me-2'>{s}</span>
                                ))}
                                <div className="post-meta align-items-center text-center">
                                </div>
                            </div>
                            <div id="post-content" className="col-md-12">
                                <div className="col-md-12" dangerouslySetInnerHTML={renderPost(post.html)} />
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5 comment-wrap container">
                <h3 className="mb-5 heading">{comments ? comments.length : 0} Comments</h3>
                {comments && comments.length > 0 && (
                    <ul className="comment-list">
                        {comments.filter(comment => comment.level === 1).map((comment, index) => (
                            <li key={index} className="comment">
                                <div className="vcard">
                                    <img src="/images/placeholders/person.jpg" alt="Image" className="img-fluid" />
                                </div>
                                <div className="comment-body">
                                    <h3>{comment.first_name} {comment.last_name}</h3>
                                    <div className="meta">{new Date(comment.created_at).toLocaleString()}</div>
                                    <p>{comment.content}</p>
                                    <button className="reply rounded" onClick={() => handleReplyClick(comment)}>Reply</button>
                                    {replyFormVisibility[comment.id] && (
                                        <LeaveComment 
                                            parent_id={comment.id} 
                                            handleSubmit={(event, cmt) => handleSubmit(event, cmt, comment.id)} 
                                            autoFocus={true}
                                        />
                                    )}
                                </div>
                                {renderNestedComments(comment.id)}
                            </li>
                        ))}
                    </ul>
                )}
                <LeaveComment
                    ref={commentRef}
                    parent_id={0}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    );
};

export default PostPage;
