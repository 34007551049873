import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const headerImage: React.CSSProperties = {
    height: '40vh',
    backgroundImage: "url('./images/vedic_knowledge.jpg')",
    padding: '0',
};

const SrilaPrabhupadaPage: React.FC = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Box className="site-cover site-cover-sm same-height overlay single-page" style={headerImage}>
            <Skeleton variant="rectangular" width="100%" height="100%" id="header-skeleton" />
            <div
                style={{ ...headerImage, display: 'none' }}
                onLoad={() => {
                document.getElementById('header-skeleton')!.style.display = 'none';
                (document.querySelector('.site-cover') as HTMLElement).style.display = 'block';
                }}
            />
            </Box>
            <section className="section">
                <div className="container">
                    <div className="row blog-entries element-animate">
                        <div className="col-md-12 col-lg-8 main-content">
                            <div className="post-content-body">
                                <br />
                                <h1>Vedsko znanje</h1>
                                <br />
                                <p>Vede su izvorni sanskrtski tekstovi drevne indijske duhovne kulture. Vede uključuju znanje o svim aspektima ljudskih nastojanja, materijalnih i duhovnih, poput skupa referentnih priručnika za postizanje konačnog uspjeha u ovom i sljedećem životu. "Veda" doslovno znači "znanje", a "vedski", u svom proširenom značenju, odnosi se na djela koja proširuju ili slijede Vede, uključujući Purane, Vedanta-sutru i Upanišade. Purane, ili "drevne povijesti," imaju za cilj učiniti Vede dostupnijima ljudima u sadašnjem dobu, a Bhagavat Purana, ili Srimad-Bhagavatam, naziva se suštinom, ili "kremom" cijele vedske literature.</p>
                                <div className="row my-4">
                                    <div className="col-md-12 mb-4">
                                         <Box position="relative">
                                            <Skeleton variant="rectangular" width="100%" height={200} />
                                            <img
                                                src="./images/vedic/1.jpg"
                                                alt="Vedic knowledge"
                                                className="img-fluid rounded"
                                                onLoad={(e) => {
                                                    e.currentTarget.style.display = 'block';
                                                    (e.currentTarget.previousElementSibling as HTMLElement)!.style.display = 'none';
                                                }}
                                                onError={(e) => {
                                                    e.currentTarget.style.display = 'none';
                                                    (e.currentTarget.previousElementSibling as HTMLElement).style.display = 'none';
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </Box>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <Box position="relative">
                                            <Skeleton variant="rectangular" width="100%" height={200} />
                                            <img
                                                src="./images/vedic/2.jpg"
                                                alt="Vedic knowledge"
                                                className="img-fluid rounded"
                                                onLoad={(e) => {
                                                    e.currentTarget.style.display = 'block';
                                                    (e.currentTarget.previousElementSibling as HTMLElement)!.style.display = 'none';
                                                }}
                                                onError={(e) => {
                                                    e.currentTarget.style.display = 'none';
                                                    (e.currentTarget.previousElementSibling as HTMLElement).style.display = 'none';
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </Box>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <Box position="relative">
                                            <Skeleton variant="rectangular" width="100%" height={200} />
                                            <img
                                                src="./images/vedic/3.jpg"
                                                alt="Vedic knowledge"
                                                className="img-fluid rounded"
                                                onLoad={(e) => {
                                                    e.currentTarget.style.display = 'block';
                                                    (e.currentTarget.previousElementSibling as HTMLElement)!.style.display = 'none';
                                                }}
                                                onError={(e) => {
                                                    e.currentTarget.style.display = 'none';
                                                    (e.currentTarget.previousElementSibling as HTMLElement).style.display = 'none';
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </Box>
                                    </div>
                                </div>
                                <p>Vede sadrže pravila ponašanja za dobrobit svih socioekonomskih klasa, vodiče za pridržavanje rituala za uzdizanje vlastite svijesti i društvenog statusa, zakone za održavanje reda unutar države i, naposljetku, upute kako probuditi duhovnu svijest.</p>
                                <p>Prema predaji, Vede postoje otkad postoje svjesna bića koja su ih čula. Ali tek od tridesetog stoljeća prije nove ere su uređene i pretočene u pisani oblik. Izvorna Veda podijeljena je na četiri - Rig, Yajur, Sama i Atharva Veda - zajedno s Puranama (povijestima),osamnaest Upanišada i konačno Vedanta-sutrom, koja sadrži vrlo sažete kodove. Kodove koje sažimaju konačni zaključak cjelokupnog znanja. Neki znanstvenici prihvaćaju samo četiri Vede kao istinski "vedske", ali mi koristimo taj izraz za označavanje cijele literature koja proširuje ili slijedi Vedska učenja.</p>
                                <p>Urednik i sastavljač Veda, Vyasadeva, sastavio je i Mahabharatu, ili "veliku povijest Zemlje", koja sadrži Krišnine upute Arjuni u poznatoj Bhagavad-giti. Vyasadevin komentar na Vedanta-sutru – Srimad-Bhagavatam – smatra se kremom cjelokupne vedske literature. Srimad-Bhagavatam posjeduje detaljne informacije o tome kako razviti ljubav prema Bogu slušajući o Njemu, Njegovim brojnim avatarima i Njegovim opunomoćenim predstavnicima.</p>
                                <br />
                            </div>
                            <br/>
                    <br/>
                    </div>
                    <div className="col-md-12 col-lg-4 sidebar">
                        <div className="sidebar-box">
                            <div className="bio text-center" >
                                <Box position="relative" style={{ textAlign: 'center' }} component="div">
                                    <Skeleton variant="circular" width={150} height={150} style={{ margin: '0 auto', display: 'block' }} id="skeleton-loader" />
                                    <img
                                        src="./images/authors/sundarananda.jpg"
                                        alt="Image Placeholder"
                                        className="img-fluid mb-3"
                                        onLoad={(e) => {
                                            e.currentTarget.style.display = 'block';
                                            document.getElementById('skeleton-loader')!.style.display = 'none';
                                        }}
                                        onError={(e) => {
                                            e.currentTarget.style.display = 'none';
                                            document.getElementById('skeleton-loader')!.style.display = 'none';
                                        }}
                                        style={{ display: 'none', borderRadius: '50%', margin: '0 auto' }}
                                    />
                                </Box>
                                <div className="bio-body">
                                    <h6><i>autor članka:</i></h6>
                                    <h6><b>Tomislav Klasnić (Sundarananda das)</b></h6>
                                    <h6></h6>
                                    <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. <Link to="" onClick={handleExpandClick}>više...</Link></p>
                                    
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <p>Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBooku</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTubeu</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SrilaPrabhupadaPage;
