import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

interface Post {
    id: number;
    title: string;
    created_date: string;
    html: string;
}

const Posts: React.FC = () => {
    const [isLoading, setLoading] = useState(true);
    const [posts, setPosts] = useState<Post[]>([]);
    
    const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({});

    const handleImageLoad = (id: number) => {
        setLoadedImages(prev => ({ ...prev, [id]: true }));
    };

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        fetch(`${config.backendUrl}/api/posts`, requestOptions)
            .then((response) => response.json())
            .then((data: Post[]) => {
                setPosts(data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    function getPosts(): Post[] {
        return posts?.length > 0 ? posts : [];
    }

    function renderPost(post: Post) {
        return { __html: post.html };
    }

    return (
        <>
            <div className="row g-3">
                <div className="col-md-12">
                    <div className="row g-3">
                    {
                        (() => {
                            if (isLoading) {
                                return (
                                    <>
                                        {Array.from(new Array(getPosts.length)).map((_, index) => (
                                            <div key={index} className="col-md-4">
                                                <Box sx={{ width: '100%', height: '347px' }}>
                                                    <Skeleton variant="rectangular" width="100%" height="100%" />
                                                </Box>
                                            </div>
                                        ))}
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        {getPosts().map((p) => (
                                            <div key={p.id} className="col-md-3">
                                                <div className="blog-entry" style={{ display: loadedImages[p.id] ? 'block' : 'none' }}>
                                                    <a href={`/posts/${p.id}`} className="img-link">
                                                        <img 
                                                            src="./images/typewritter.jpg" 
                                                            alt="Image" 
                                                            className="img-fluid" 
                                                            onLoad={() => handleImageLoad(p.id)} 
                                                            style={{ 
                                                                display: 'block',
                                                                opacity: loadedImages[p.id] ? 1 : 0,
                                                                transition: 'opacity 0.5s ease-in-out',
                                                                width: '100%',
                                                                height: 'auto'
                                                            }} />
                                                    </a>
                                                    <span className="date">{p.created_date}</span>
                                                    <h5>{p.title}</h5>
                                                    <p><Link to={`/posts/${p.id}`} className="btn btn-sm btn-outline-primary">otvori</Link></p>
                                                </div>
                                                {!loadedImages[p.id] && (
                                                    <Box sx={{ width: '100%', height: '347px' }}>
                                                        <Skeleton variant="rectangular" width="100%" height="100%" />
                                                    </Box>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                );
                            }
                        })()
                    }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Posts;
