import { useState, useContext, FormEvent } from "react";
import Input from './form/Input';
import { useNavigate, useOutletContext } from "react-router-dom";
import { appContext, alertTypes } from '../App';
import config from '../config';

interface OutletContext {
    jwtToken: string;
    setAlertTitle: (title: string) => void;
    setAlertMessage: (message: string) => void;
}

const RequestResetPasswordLinkPage: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const navigate = useNavigate();

    const appContextValue = useContext(appContext);
    if (!appContextValue) {
        throw new Error("appContextValue is undefined");
    }
    const { toggleAlert, changeAlertType } = appContextValue;
    const { setAlertTitle, setAlertMessage } = useOutletContext<OutletContext>();

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        // build the request payload
        const payload = {
            email: email,
        };

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        };

        console.log(`${config.backendUrl}/api/forgot-password`);

        fetch(`${config.backendUrl}/api/forgot-password`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Greška");
                    setAlertMessage(data.message);
                    toggleAlert(true);
                    console.log(data.error);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Password reset email sent!");
                    setAlertMessage(data.message);
                    //navigate('/');
                }
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err.message);
                toggleAlert(true);
                console.log(err)
            });
    };

    return (
        <div className="col-md-6 offset-md-3">
            <br/>
            <h2>Zaboravili ste svoju lozinku?</h2>
            <hr/>

            <form onSubmit={handleSubmit}>
                <Input 
                    title="Email adresa"
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="email-new"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <hr/>

                <input 
                    type="submit"
                    className="btn btn-primary"
                    value="Pošalji link"
                />
            </form>
            <br/>
            <br/>
        </div>
    );
};

export default RequestResetPasswordLinkPage;
