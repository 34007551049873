import { FC } from 'react';
import Posts from './Posts';

const PostsPage: FC = () => {
    return (
        <>
            <section className="section posts-entry">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <h5 className="posts-entry-title">Sve objave</h5>
                        </div>
                        {/* <div className="col-sm-6 text-sm-end"><a href="category.html" className="read-more">View All</a></div> */}
                    </div>
                    <Posts />
                </div>
            </section>
        </>
    );
}

export default PostsPage;
