import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import config from '../config';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

dayjs.extend(utc);
dayjs.locale('hr');
dayjs.extend(advancedFormat)

interface Event {
    id: number;
    title: string;
    category: number;
    start_datetime: string;
    end_datetime: string;
}

interface EventCategory {
    id: number;
    value: string;
    img: string;
}

const Events = () => {
    const [isLoading, setLoading] = useState(true);
    const [events, setEvents] = useState<Event[]>([]);
    const navigate = useNavigate();

    const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({});

    const handleImageLoad = (id: number) => {
        setLoadedImages(prev => ({ ...prev, [id]: true }));
    };

    const eventCategories: EventCategory[] = [
        {
            id: 1,
            value: "Bhagavad Gita",
            img: './../images/events/bg.jpg'
        },
        {
            id: 2,
            value: "Srimad Bhagavatam",
            img: './../images/events/sb.jpg'
        },
        {
            id: 3,
            value: "Kirtan večer",
            img: './../images/events/kirtan.jpg'
        },
        {
            id: 4,
            value: "Caitanya Caritamrta",
            img: './../images/events/cc.jpg'
        },
        {
            id: 5,
            value: "Posebna prigoda",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 6,
            value: "Festival",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 7,
            value: "Ostalo",
            img: './../images/events/byc-logo.png'
        },
    ];

    function getEventImage(event: Event): string {
        const result = eventCategories.find(({ id }) => id === event.category);
        return result?.img || '';
    }

    function loadEvents() {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        }

        fetch(`${config.backendUrl}/api/events-from-today`, requestOptions)
            .then((response) => response.json())
            .then((data: Event[]) => {
                setEvents(data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        loadEvents();
    }, []);

    function getEvents(): Event[] {
        return events?.length > 0 ? events : [];
    }

    function getEventDates(event: Event): string {
        const startDate = dayjs.utc(event.start_datetime);
        const endDate = dayjs.utc(event.end_datetime);
        const formattedStartDate = startDate.format('dddd, D. MMM, YYYY HH:mm');
        const formattedEndDate = endDate.format('dddd, D. MMM, YYYY HH:mm');

        if (startDate.isSame(endDate, 'date')) {
            return `${startDate.format('dddd, D. MMM')} (${startDate.format('HH:mm')} - ${endDate.format('HH:mm')})`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const navigateTo = (event: React.MouseEvent<HTMLAnchorElement>, relativeUrl: string) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            <div className="row g-3">
                <div className="col-md-12">
                    <div className="row g-3 align-self-center">
                    {
                        (() => {

                                if (isLoading) {
                                    return (
                                        <>
                                            {Array.from(new Array(getEvents().length)).map((_, index) => (
                                                <div key={index} className="col-md-4">
                                                    <Box sx={{ width: '100%', height: '347px' }}>
                                                        <Skeleton variant="rectangular" width="100%" height="100%" />
                                                    </Box>
                                                </div>
                                            ))}
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            {getEvents().map((e) => (
                                                <div key={e.id} className="col-md-4">
                                                    <div className="blog-entry" style={{ display: loadedImages[e.id] ? 'block' : 'none' }}>
                                                        <a className="img-link" onClick={(event) => navigateTo(event, `/events/${e.id}`)}>
                                                            <img 
                                                                src={getEventImage(e)} 
                                                                className="img-fluid" 
                                                                alt={e.title}
                                                                onLoad={() => handleImageLoad(e.id)}
                                                                style={{
                                                                    display: loadedImages[e.id] ? 'block' : 'none',
                                                                    opacity: loadedImages[e.id] ? 1 : 0,
                                                                    transition: 'opacity 0.5s ease-in-out',
                                                                    width: '100%',
                                                                    height: 'auto'
                                                                }}
                                                            />
                                                        </a>
                                                        <h6><span className="date">{getEventDates(e)}</span></h6>
                                                        <h5><strong>{e.title}</strong></h5>
                                                        <p><Link to={`/events/${e.id}`} className="btn btn-sm btn-outline-primary">Otvori</Link></p>
                                                    </div>
                                                    {!loadedImages[e.id] && (
                                                        <Box sx={{ width: '100%', height: '347px' }}>
                                                            <Skeleton variant="rectangular" width="100%" height="100%" />
                                                        </Box>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    );
                                }
                        })()
                    }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Events;
